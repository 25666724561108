import CarouselButton, {
  CarouselButtonSize,
} from '@/components/molecules/CarouselButton/CarouselButton';
import CarouselDotNavigation from '@/components/molecules/CarouselDotNavigation/CarouselDotNavigation';
import CarouselPreviewNavigation from '@/components/molecules/CarouselPreviewNavigation/CarouselPreviewNavigation';
import useWindowSize from '@/lib/hooks/windowSize/WindowSize';
import {
  CarouselContext,
  CarouselProvider,
  CarouselProviderProps,
  Slide,
  Slider,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useCallback, useContext, useEffect, useState } from 'react';

/**
 * The default classes for the Slider Component.
 *
 * @constant {string} defaultSliderClasses
 */
const defaultSliderClasses = ' focus-visible:outline-focus-400 mb-8';
/**
 * The default classes for the Slide Component.
 *
 * @constant {string} defaultSlideClasses
 */
const defaultSlideClasses =
  'focus-visible:outline-focus-400 px-[0.5rem] sm:px-[0.75rem] md:px-[1rem]';

/**
 * The props for the navigation buttons
 *
 * @interface ICarouselControlsNavigationButtonsProps
 */
export interface ICarouselControlsNavigationButtonsProps {
  /**
   * Whether to show the navigation buttons
   *
   * @default true
   */
  showNavigationButtons?: boolean;
  /**
   * The size of the navigation buttons
   *
   * @default 'regular'
   */
  navigationButtonSize?: CarouselButtonSize;
  /**
   * The optional verticalOffsetClass
   *
   * @default defaultVerticalOffsetClass
   */
  verticalOffsetClass?: string;
  /**
   * The optional classes for the right arrow
   *
   * @default ''
   */
  rightArrowClasses?: string;
  /**
   * The optional classes for the left arrow
   *
   * @default ''
   */
  leftArrowClasses?: string;
}

/**
 * The props for the dot navigation
 *
 * @interface ICarouselControlsDotNavigationProps
 */
interface ICarouselControlsDotNavigationProps {
  /**
   * The optional dot navigation space in pixels
   *
   * @default 16
   */
  space?: number;
  /**
   * The optional dot navigation font size class
   *
   * @default 'text-[24px]'
   */
  fontSizeClass?: string;
}

/**
 * The props for the preview navigation
 *
 * @interface ICarouselControlsPreviewNavigation
 */
interface ICarouselControlsPreviewNavigation {
  /** The carousel images */
  images: string[];
}

/**
 * The CarouselControlsProps
 *
 * @interface ICarouselControlsProps
 */
interface ICarouselControlsProps {
  /** The carousel images */
  images?: string | string[];
  /** The navigation buttons for the carousel controls */
  navigationButtons?: ICarouselControlsNavigationButtonsProps;
  /** The dot navigation for the carousel controls */
  dotNavigation?: ICarouselControlsDotNavigationProps;
  /** The preview navigation configuration */
  previewNavigation?: ICarouselControlsPreviewNavigation;
}

/**
 * Carousel Controls Handles the Carousel Context and Controls
 *
 * @param {ICarouselControlsProps} props - The props for the Carousel Controls
 *   Component.
 * @returns {void} Carousel Controls Component with Context
 */
export function CarouselControls({
  navigationButtons,
  dotNavigation,
  previewNavigation,
}: ICarouselControlsProps) {
  /**
   * @property {boolean} showNavigationButtons - Whether to show the navigation
   * @property {CarouselButtonSize} navigationButtonSize - The size of the
   *   navigation buttons
   * @property {string} verticalOffsetClass - The optional vertical offset class
   */
  const {
    showNavigationButtons = true,
    navigationButtonSize = 'regular',
    verticalOffsetClass = '',
    rightArrowClasses = '',
    leftArrowClasses = '',
  } = navigationButtons || {};
  /**
   * The carousel context
   *
   * @constant {CarouselContext} carouselContext
   */
  const carouselContext = useContext(CarouselContext);
  /**
   * The current slide state
   *
   * @constant {number} currentSlide
   */
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide
  );
  /**
   * The total slides state
   *
   * @constant {number} totalSlides
   */
  const [totalSlides, setTotalSlides] = useState(
    carouselContext.state.totalSlides
  );
  /**
   * The visible slides state
   *
   * @constant {number} visibleSlides
   */
  const [visibleSlides, setVisibleSlides] = useState(
    carouselContext.state.visibleSlides
  );
  /**
   * The
   *
   * @constant {string} breakpoint
   */
  const { breakpoint } = useWindowSize();

  /** Subscribes to any Carousel Context changes and sets it to the state */
  useEffect(() => {
    /** OnChange Handles the change of the carousel context */
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
      setTotalSlides(carouselContext.state.totalSlides);
      setVisibleSlides(carouselContext.state.visibleSlides);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  const firstSlide = currentSlide === 0;
  const lastSlide = currentSlide + visibleSlides >= totalSlides;

  /**
   * Set Slide Sets the slide to the given index
   *
   * @param {number} index - The index of the slide to set.
   * @returns {void}
   */
  const setSlide = useCallback(
    (index: number): void => {
      carouselContext.setStoreState({ currentSlide: index });
    },
    [carouselContext]
  );

  return (
    <div data-testid="carousel-controls">
      {/* Forward and Back Buttons */}
      {!firstSlide && showNavigationButtons && (
        <CarouselButton
          icon="arrowLeft"
          data-testid="carousel-controls-back"
          onClick={() => setSlide(currentSlide - 1)}
          className={`hidden md:flex absolute top-[calc(((100%-120px)/2))] z-[1] ${verticalOffsetClass} ${
            leftArrowClasses ? leftArrowClasses : 'left-[5px]'
          }`}
          size={navigationButtonSize}
        />
      )}
      {!lastSlide && showNavigationButtons && (
        <CarouselButton
          icon="arrowRight"
          data-testid="carousel-controls-forward"
          onClick={() => setSlide(currentSlide + 1)}
          className={`hidden md:flex absolute top-[calc(((100%-120px)/2))] z-[1] ${verticalOffsetClass}  ${
            rightArrowClasses ? rightArrowClasses : 'right-[5px]'
          }`}
          size={navigationButtonSize}
        />
      )}
      {breakpoint === 'md' && previewNavigation?.images !== undefined ? (
        <CarouselPreviewNavigation
          images={previewNavigation?.images as string[]}
          currentSlide={currentSlide}
          setSlide={setSlide}
          classes={'hidden md:flex'}
        />
      ) : (
        <CarouselDotNavigation
          currentSlide={currentSlide}
          setSlide={setSlide}
          totalSlides={totalSlides}
          fontSizeClass={dotNavigation?.fontSizeClass}
        />
      )}
    </div>
  );
}

/**
 * ICarousel Interface for the Carousel Component.
 *
 * @interface
 */
export interface ICarousel
  extends Partial<CarouselProviderProps>,
    ICarouselControlsProps {
  /** The children to display. */
  children: React.ReactNode[];
  /**
   * The classes to apply to the Carousel Component.
   *
   * @default ''
   */
  classes?: string;
  /**
   * The classes to apply to the Slider Component.
   *
   * @default ''
   */
  sliderClasses?: string;
  /**
   * The classes to apply to the Slide Component.
   *
   * @default ''
   */
  slideClasses?: string;
}

/**
 * Carousel Carousel Component for displaying a list of items in a carousel.
 *
 * @param {ICarousel} props - The props for the Carousel Component.
 * @returns {React.FC<ICarousel>} Carousel Component
 */
const Carousel: React.FC<ICarousel> = ({
  naturalSlideWidth = 300,
  naturalSlideHeight = 400,
  visibleSlides,
  children,
  classes,
  sliderClasses = defaultSliderClasses,
  slideClasses = defaultSlideClasses,
  navigationButtons,
  dotNavigation,
  previewNavigation,
}) => {
  const [calculatedVisibleSlides, setCalculatedVisibleSlides] = useState(1);

  const size = useWindowSize();

  /** Sets the number of visible slides based on the window size. */
  useEffect(() => {
    if (size.breakpoint === 'xs') {
      setCalculatedVisibleSlides(1);
    } else if (size.breakpoint === 'sm') {
      setCalculatedVisibleSlides(2);
    } else {
      setCalculatedVisibleSlides(3);
    }
  }, [size.breakpoint]);

  return (
    <CarouselProvider
      naturalSlideWidth={naturalSlideWidth}
      naturalSlideHeight={naturalSlideHeight}
      visibleSlides={visibleSlides || calculatedVisibleSlides}
      isIntrinsicHeight={true}
      totalSlides={children.length}
    >
      <div data-testid="carousel" className={`relative ${classes}`}>
        <Slider className={sliderClasses}>
          {children.map((child, index) => (
            <Slide
              key={index}
              index={index}
              tabIndex={-1}
              className={slideClasses}
            >
              {child}
            </Slide>
          ))}
        </Slider>
        <CarouselControls
          navigationButtons={navigationButtons}
          dotNavigation={dotNavigation}
          previewNavigation={previewNavigation}
        />
      </div>
    </CarouselProvider>
  );
};

Carousel.defaultProps = {
  classes: '',
};

export default Carousel;
